<template>
    <div class="grid">
        <div class="col-12">
            <ProgressBar v-if="loading" mode="indeterminate" style="height: 0.3em" />
            <div :style="loading ? 'opacity: 30%;' : ''">
                <div class="card p-fluid w-full">
                    <div class="flex flex-row gap-2 align-items-center text-800 mb-1">
                        <AppInfoManual nomeTelaDoManual="profissionais-form" />
                        <h3 class="ml-1">{{ title }}</h3>
                    </div>

                    <Toast />

                    <div class="">
                        <div class="field">
                            <label for="name">Nome</label>
                            <InputText
                                id="name"
                                v-model.trim="form.nome"
                                :class="{ 'p-invalid': campoNomeVazio }"
                                autocomplete="off"
                                autofocus
                                required="true"
                                @keypress.enter="salvarProfissional()"
                            />
                            <small class="p-error" v-if="campoNomeVazio">Nome é obrigatório.</small>
                        </div>
                        <div class="field">
                            <label for="specialty">Procedimentos</label>
                            <MultiSelect
                                id="procedimentos"
                                v-model="form.procedimentos"
                                :options="procedimentos"
                                :virtual-scroller-options="{ itemSize: 34, lazy: true }"
                                dataKey="id"
                                display="chip"
                                filter
                                option-label="name"
                                option-value="id"
                                placeholder="Selecione..."
                            />
                        </div>
                        <div class="field">
                            <label for="usuario">Usuário</label>
                            <DropdownUsuario id="usuario" v-model="form.user" />
                        </div>
                        <div class="field field-checkbox">
                            <label class="m-2 ml-0" for="realizarAgendamentosForaDistribuicao">Realizar agendamentos fora da distribuição</label>
                            <InputSwitch id="realizarAgendamentosForaDistribuicao" v-model="form.realizarAgendamentosForaDistribuicao" />
                        </div>
                    </div>

                    <div class="flex mt-5">
                        <Button label="Salvar" icon="pi pi-check" class="p-button-primary w-6" @click="salvarProfissional()" />
                        <Button label="Voltar" icon="pi pi-times" class="p-button-text w-3 ml-auto" @click="$router.push('/profissionais')" />
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import AreaMedicaService from '@/services/AreaMedicaService';
import { getCurrentCompany } from '@/services/store';
import BaseService from '../../services/BaseService';
import DropdownUsuario from '../usuarios/components/DropdownUsuario';
import AppInfoManual from '../../components/AppInfoManual.vue';

export default {
    components: { DropdownUsuario, AppInfoManual },
    data() {
        return {
            form: {
                id: undefined,
                nome: undefined,
                user: undefined,
                procedimentos: [],
                realizarAgendamentosForaDistribuicao: false
            },
            procedimentos: [],
            campoNomeVazio: false,
            loading: false
        };
    },
    async mounted() {
        this.loading = true;
        this.$service = new AreaMedicaService('/profissional_medico');
        this.$serviceProcedimentos = new BaseService('/procedures');
        try {
            await this.loadProcedimentos();
            if (this.$route.params.id) {
                const { data } = await this.$service.findById(this.$route.params.id);
                this.form = {
                    ...data,
                    procedimentos: data.procedimentos.map((procedimento) => procedimento.id)
                };
            }
        } catch (error) {
            this.loading = false;
            this.$toast.add({
                severity: 'error',
                summary: 'Problemas ao carregar informações!',
                detail: error.message,
                life: 3000
            });
        }
        this.loading = false;
    },
    computed: {
        title() {
            return this.$route.params.id ? 'Editar profissional' : 'Cadastrar profissional';
        },
        getCurrentCompany() {
            return getCurrentCompany();
        }
    },
    watch: {
        getCurrentCompany(newValue, oldValue) {
            if (newValue !== oldValue) {
                alert('Não é possível trocar a empresa no fluxo de criação/edição de um cadastro!');
                this.$router.push({ path: 'list' });
            }
        }
    },
    methods: {
        async loadProcedimentos() {
            const { data } = await this.$serviceProcedimentos.findAll({ limit: 9999 });
            this.procedimentos = data.items.map((procedimento) => ({
                name: procedimento.name,
                id: procedimento.id
            }));
        },
        async salvarProfissional() {
            try {
                if (!this.form.nome?.trim()) {
                    this.campoNomeVazio = true;
                    return;
                }

                const procedimentos = [];
                if (!this.form.procedimentos?.length) {
                    procedimentos.push(...this.procedimentos.map(({ id }) => ({ id })));
                } else procedimentos.push(...this.form.procedimentos.map((id) => ({ id })));

                await this.$service.save({ ...this.form, procedimentos });
                this.$toast.add({
                    severity: 'success',
                    summary: 'Profissional salvo com sucesso!',
                    life: 3000
                });
                this.$router.replace('list');
            } catch (err) {
                this.$toast.add({
                    severity: 'error',
                    summary: 'Problemas ao salvar o profissional!',
                    life: 3000
                });
            }
        }
    }
};
</script>
